.modal-body {
	font-size: 16px;
}

.modal__header {
	margin: 0;
	padding: 0;
	font-size: 20px;
}

.modal-footer {
	display: flex;
	flex-direction: row;
	column-gap: 15px;
	margin-top: 30px;
}
