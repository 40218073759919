// z-index values
$layer_0: 0;
$layer_1: 100;
$layer_2: 200;
$layer_3: 1000;
$layer_4: 1200;
$layer_5: 1800;
$layer_6: 1999;
$layer_7: 2000;
$layer_8: 3000;
$layer_9: 10000;


// colors
$color_red: rgba(224, 60, 63, 1);
$color_green: rgba(0, 166, 36, 1);
$color_blue: rgba(0, 122, 255, 1);
