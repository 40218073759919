.refresh-notice {
	padding: 10px;
	font-size: 14px;
}

.refresh-notice__title {
	margin: 0;
	padding: 0;
	color: #000;
	font-size: 18px;
}
