@import "./config";

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html {
  font-size: 14px;
}

body {
  font-size: 1rem;
}

@media only screen and (min-width: 680px) {
  html {
    font-size: 16px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.site-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;

  header,
  main,
  footer {
    width: 100%;
  }
}

main {
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
}

@media only screen and (min-width: 1200px) {
  main {
    padding: 35px 15px;
  }
}

// Text Headers
.header--lvl-1 {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: normal;
}

.header--lvl-2 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
}

.header--dash {
  &:before {
    content: "\2014";
  }
}

@media only screen and (min-width: 680px) {
  .header--lvl-1 {
    font-size: 32px;
  }
}

// Buttons
.button {
  position: relative;
  display: inline-block;
  padding: 8px 16px;
  color: inherit;
  font-family: inherit;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;

  .icon {
    display: inline-block;
    margin-right: 5px;
  }
}

.button--small {
  padding: 4px 8px;
}

.button--large {
  padding: 10px 20px;
}

.button--outline {
  color: #585858;
  background-color: #fff;
  border-color: #585858;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #e7e7e7;
  }
}

.button--outline-primary {
  &:hover {
    color: #fff;
    background-color: $color_red;
    border-color: $color_red;
  }
}

.button--filled {
  color: #fff;
  background-color: $color_red;
  border-color: $color_red;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: darken($color_red, 10%);
  }
}

.button--text {
  padding: 0;
  color: inherit;
  font-size: 12px;
  font-weight: normal;
  text-decoration: underline;
  background-color: transparent;
  border: none;
}

.button--text-small {
  font-size: 11px;
}

.button--alert {
  color: $color_red;
}

.button--trash {
  padding: 0;

  .icon {
    margin-right: 0;
  }
}

.button--close {
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
  padding-right: 0;
  padding-left: 0;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  border: none;
}

.button--search-submit {
  background-color: rgba(218, 218, 218, 0.2);
  border: none;
  border-left: 1px solid #c7c7c7;
}

.button--disabled {
  opacity: 0.2;
  cursor: default;
}

.button-spinner,
.image-spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
}

.image-spinner {
  z-index: $layer_4;
}

.link--underline {
  text-decoration: underline;
}

@media only screen and (min-width: 520px) {
  .button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .button--text {
    font-size: 14px;
  }

  .button--text-small {
    font-size: 12px;
  }

  .button--small {
    padding: 8px 16px;
  }

  .button--large {
    padding: 15px 30px;
  }

  .button--close {
    font-size: 12px;
    padding-right: 0;
    padding-left: 0;
  }
}

@media only screen and (min-width: 680px) {
  .button {
    padding: 10px 25px;
  }

  .button--trash {
    padding: 1px 7px;
  }

  .button--text {
    padding: 0;
  }

  .button--search-submit {
    padding: 10px;
  }

  .button--small {
    padding: 8px 16px;
  }

  .button--large {
    padding: 20px 40px;
  }

  .button--close {
    font-size: 12px;
    padding-right: 0;
    padding-left: 0;
  }
}

.container {
  padding: 0 15px;
}

// Header
.header {
  background-color: #fff;
  box-shadow: 0 2px 15px rgba(199, 199, 199, 0.3);

  .logo {
    grid-column: 1;
  }

  .account {
    display: none;
    justify-self: flex-end;
    grid-column: 4;
  }

  .search__form {
    grid-column: 1 / span 4;
    grid-row: 2;
    width: auto;
    max-width: 500px;
  }
}

.header__inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 1200px;
  padding: 35px 15px;
  margin: 0 auto;
}

@media only screen and (min-width: 680px) {
  .header {
    .search__form {
      grid-column: 2 / span 2;
    }

    .account {
      display: block;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px 30px;

    .search__form {
      width: 100%;
      max-width: 650px;
    }
  }
}

// Footer
.site-footer__primary {
  padding: 15px 0;
  background-color: #222b3e;
}

.site-footer__secondary {
  color: #585858;
  font-size: 11px;
  background-color: rgba(218, 218, 218, 0.25);

  .button--text {
    font-size: inherit;
  }
}

.site-footer__inner {
  padding: 10px 0;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-separator {
  display: none;
  margin: 0 5px;
  color: #c7c7c7;
}

.site-footer__nav {
  margin-top: 30px;
}

.site-footer__item {
  margin: 15px 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.site-footer__link {
  color: #fff;
  font-size: 16px;

  &:hover {
    text-decoration: underline;
  }
}

.footer-trademark {
  display: block;
  margin-top: 5px;
}

@media only screen and (min-width: 680px) {
  .footer-separator {
    display: inline-block;
  }

  .site-footer__primary {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .site-footer__nav {
    display: flex;
    flex-direction: row;
    column-gap: 45px;
    margin-top: 0;
  }

  .footer-trademark {
    display: inline-block;
    margin-top: 0;
  }
}

// breadcrumbs
.breadcrumbs {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  margin: 30px auto 0;
  padding: 0 15px;
  font-size: 14px;
  color: #222b3e;
}

.breadcrumbs__item {
  &::after {
    content: "//";
    display: inline-block;
    margin: 0 10px;
    color: #c7c7c7;
  }
}

.breadcrumbs__link {
  text-decoration: underline;
}

.breadcrumbs__label {
  font-weight: bold;
}

@media only screen and (min-width: 680px) {
  .breadcrumbs {
    margin-top: 60px;
  }
}

@media only screen and (min-width: 1200px) {
  .breadcrumbs {
    padding: 0 30px;
  }
}

// Logo
.logo__image {
  display: block;
  max-width: 217px;
  height: auto;
}

// Account
.account {
  display: none;
  color: #222b3e;
  font-size: 14px;
  white-space: nowrap;
}

.account__toggle {
  display: inline-block;
  color: inherit;
  font-family: inherit;
  line-height: 1;
  background-color: transparent;
  border: none;
}

// Search
.search {
  margin: 120px 0 60px;
}

.search-form {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 20px;
}

.search-form__no-results {
  grid-column: 1;
  grid-row: 2;
}

.search-form__section {
  margin-top: 20px;
}

.search-form__section--input {
  grid-column: 1 / span 2;
}

.or {
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  text-transform: uppercase;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 1px;
    border-top: 1px solid;
  }

  &::before {
    margin-right: 5px;
  }

  &::after {
    margin-left: 5px;
  }
}

.search__form {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 650px;
  // margin-top: 20px;
  border: 1px solid #c7c7c7;

  &--error {
    border-color: $color_red;
    box-shadow: fade-out($color_red, 0.85) 0 0 5px 2px;
    outline: 1px solid $color_red;
  }

  .button--source-toggle {
    margin-right: 8px;
  }
}

.search__input {
  flex: 1;
  margin: 5px 10px;
  padding: 10px;
  line-height: 1;
  border: none;
}

.search__form--small {
  margin-top: 0;

  .search__input {
    margin: 0 5px;
    padding: 5px;
  }

  .button--search-submit {
    padding: 4px 8px;
  }

  .button--source-toggle {
    padding: 4px 8px;
  }
}

@media only screen and (min-width: 680px) {
  .search-form {
    display: inline-grid;
    grid-template-columns: minmax(auto, 600px) auto auto;
  }

  .search-form__section--input {
    grid-column: auto;
  }
}

// Truck detail
.truck-detail {
  margin: 15px 0 30px;
}

.truck-detail__header {
  margin-bottom: 20px;
  font-size: 14px;
}

.truck-detail__title {
  margin: 0 0 15px;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
}

.truck-detail__sub {
  display: block;
  color: rgba(88, 88, 88, 0.8);
  font-size: 20px;
  text-transform: uppercase;
}

@media only screen and (min-width: 680px) {
  .truck-detail {
    margin: 15px 0 30px;
  }

  .truck-detail__header {
    margin-bottom: 60px;
  }

  .truck-detail__title {
    font-size: 36px;
  }
}

// Truck Images
.truck-images {
  position: relative;
  margin: 45px 0;
}

.truck-images__upload-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.truck-images__guidelines {
  font-size: 12px;

  a {
    font-weight: bold;
    text-decoration: underline;
  }
}

.truck-image__disabled {
  user-select: none;
  pointer-events: none;
}

.truck-image__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999
}

.truck-images__photos {
  margin: 20px 0;

  .truck-image {
    margin-bottom: 20px;
  }
}

.truck-images__approved {
  padding-left: 15px;
  padding-right: 15px;
}

.draggable-track {
  padding: 15px;
}

.draggable-truck {
  width: 100%;
  margin-bottom: 30px;
}

@media only screen and (min-width: 520px) {
  .truck-images__upload-actions {
    column-gap: 15px;
  }

  .truck-images__photos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;

    .truck-image {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: 680px) {
  .truck-images__photos {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    margin: 20px 0 40px;

    .truck-image__name {
      font-size: 14px;
    }

    .truck-image__size {
      font-size: 12px;
    }
  }

  .truck-images__approved {
    padding-left: 0;
  }

  .draggable-track {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
  }

  .draggable-truck {
    width: calc(50% - 15px);
    margin-bottom: 15px;
    margin-left: 15px;
  }
}

@media only screen and (min-width: 860px) {
  .draggable-truck {
    width: calc(25% - 15px);
  }
}

@media only screen and (min-width: 1000px) {
  .truck-images__photos {
    .truck-image__name {
      font-size: 18px;
    }

    .truck-image__size {
      font-size: 16px;
    }
  }
}

.truck-images__upload {
  padding: 15px 0;
  background-color: rgba(218, 218, 218, 0.25);
}

@media only screen and (min-width: 680px) {
  .truck-images__upload {
    padding: 45px 0;
  }
}

.truck-images__upload + .truck-images__upload {
  padding-top: 0;
}

.truck-image--review {
  .truck-image__photo {
    opacity: 0.4;
  }
}

.truck-image {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  margin-bottom: 15px;
  background-color: #fff;
  border: 1px solid transparent;
  transition: 0.2s all ease-in-out;

  &:hover,
  &--dragging {
    background-color: rgba(248, 248, 248, 1);
    border-color: rgba(218, 218, 218, 1);
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
  }

  .draggable-truck & {
    cursor: move;
  }
}

.truck-image__inner {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.truck-image__expand {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  cursor: pointer;

  .truck-image__expand-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $layer_1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    background-color: rgba(34, 43, 62, 0.5);
    opacity: 0;
    transition: opacity 0.1s ease-in;
  }

  &:hover {
    .truck-image__expand-overlay {
      opacity: 1;
    }
  }

  img,
  video {
    display: block;
    max-width: 100%;
    height: auto;
    aspect-ratio: 4 / 3;
    object-fit: cover;
  }
}

.truck-image__status {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $layer_2;
  padding: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  background-color: $color_red;
  text-transform: uppercase;
  text-align: center;
}

.truck-image__status--pending {
  background-color: #c7c7c7;
}

.truck-image__photo,
.truck-image__video {
  display: block;
  position: relative;
  z-index: $layer_1;
  max-width: 100%;
  height: auto;
  aspect-ratio: 4 / 3;
  object-fit: cover;
}

.truck-image__details {
  padding: 10px 5px 5px;
}

@media only screen and (min-width: 680px) {
  .truck-image__details {
    padding: 15px 10px 5px;
  }
}

.truck-image__header {
  margin-bottom: 15px;
}

.truck-image__name {
  margin-top: 0;
  margin-bottom: 0;
  color: #222b3e;
  font-size: 18px;
  overflow-wrap: anywhere;
}

.truck-image__size {
  margin-top: 5px;
  color: #585858;
  font-size: 16px;
}

.truck-image__date {
  margin: 3px 0;
  color: #585858;
  font-size: 11px;
}

.truck-image__placeholder {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  aspect-ratio: 4/ 3;
  background-color: rgba(218, 218, 218, 1);
}

.truck-image__progress {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 75%;
  margin: auto;
}

.truck-image__check {
  display: flex;
  align-items: center;
  margin-top: auto;
  padding: 5px 10px 10px;

  .text-note {
    display: inline-block;
    margin-left: 5px;
  }
}

.selected-note {
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
  text-transform: uppercase;
}

// image approval
.approval {
  // display: grid;
  // grid-template-columns: 1fr 2fr;
  // column-gap: 20px;
  position: relative;
  margin: 50px 0 30px;

  &:first-of-type {
    margin-top: 20px;
  }
}

.approval--loading .approval__inner {
  opacity: 0.3;
}

.approval__loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $layer_7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.approval__details {
  margin-top: 45px;
}

@media only screen and (min-width: 680px) {
  .approval {
    margin-top: 75px;
  }

  .approval__inner {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 20px;
  }

  .approval__details {
    margin-top: 0;
  }
}

.approval__image-container {
  position: relative;
  display: inline-block;
}

.approval__image-container {
  &.is--dropzoned::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(218, 218, 218, 0.8);
  }

  .dropzone {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $layer_2;
    margin: 10px;

    div[role="button"] {
      justify-content: center;
      height: 100%;
    }
  }
}

.approval__image-actions {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  margin-top: 10px;
}

.approval-form {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 18px;
}

.approval-form__item {
  margin-bottom: 20px;
}

.approval-form__label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
}

.approval-form__field {
  width: 100%;
  padding: 15px;
  line-height: 1;
  border: 1px solid #c7c7c7;
}

.approval-form__note {
  font-size: 12px;
}

.approval-form__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
}

.approval-form__value {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

.action--end {
  margin-left: auto;
}

@media only screen and (min-width: 680px) {
  .approval-form__actions {
    column-gap: 15px;
  }
}

// utilities
.full-width {
  background-color: rgba(218, 218, 218, 0.25);
}

.full-width__inner {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

@media only screen and (min-width: 1200px) {
  .full-width {
    margin-left: calc(-100vw / 2 + 1190px / 2);
    margin-right: calc(-100vw / 2 + 1190px / 2);
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

.text-note {
  color: rgba(34, 43, 62, 0.7);
  font-size: 10px;
}

.text-alert {
  color: $color_red;
}

// custom checkbox
:root {
  --form-control-color: #121212;
  --form-control-disabled: #c7c7c7;
}

input[type="checkbox"] {
  appearance: none;
  display: inline-flex;
  width: 1.15em;
  height: 1.15em;
  margin: 0;
  color: currentColor;
  font: inherit;
  background-color: var(--form-background);
  border: 1px solid #c7c7c7;
  transform: translateY(-0.075em);
  align-items: center;
  justify-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  background-color: CanvasText;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  box-shadow: 0 0 0 2px fade-out($color_blue, 0.2);
}

input[type="checkbox"]:disabled {
  opacity: 0.4;
  --form-control-color: var(--form-control-disabled);
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.progress-bar {
  height: 20px;
  background-color: #fff;
}

.progress-bar__loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5%;
  background-color: $color_green;
}

// -- temp
.flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

@media only screen and (max-width: 480px) {
  .flex {
    display: block;
  }
}

.page-loader {
  margin: 15px auto;
}

.requires-approval,
.recent-trucks {
  padding-top: 45px;
  padding-bottom: 45px;
}

.requires-approval__trucks,
.recent-trucks__list {
  margin-top: 15px;
}

.truck-card {
  display: block;
  margin-bottom: 20px;
  padding: 15px;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #c7c7c7;
  transition: 0.2s all ease-in-out;

  &:hover {
    background-color: rgba(218, 218, 218, 0.2);
    border-color: rgba(218, 218, 218, 1);
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
  }
}

.truck-card__image {
  position: relative;
  display: inline-block;
}

.truck-card__photo {
  display: block;
  max-width: 100%;
  height: auto;
}

.truck-card__header {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}

.truck-card__id {
  margin: 0;
  font-size: 28px;
  overflow-wrap: anywhere;
}

.truck-card__count {
  margin: 4px 0;
}

.truck-card__last-updated {
  margin-top: 15px;
  font-size: 14px;
}

.truck-card__status {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $layer_2;
  padding: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  background-color: $color_red;
  text-transform: uppercase;
  text-align: center;
}

.needs-optimization {
  display: inline-flex;
  align-items: center;
  color: $color_red;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.tag-select-container {
  z-index: $layer_3;
}

@media only screen and (min-width: 680px) {
  .requires-approval__trucks,
  .recent-trucks__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
}

@media only screen and (min-width: 860px) {
  .truck-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }

  .truck-card {
    font-size: 18px;
  }

  .truck-card__header {
    font-size: 22px;
  }

  .truck-card__id {
    font-size: 32px;
  }
}

//modal
.modal-body {
  font-size: 18px;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  margin-top: 30px;
}

// sent for review notification
.review-notice {
  margin: 20px 0;
  padding: 20px;
  color: #222b3e;
  font-weight: bold;
  background-color: fade-out($color_green, 0.85);
  border: 2px solid $color_green;
  border-radius: 5px;
}

.loading-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $layer_4;
  display: flex;
  justify-content: center;
  padding-top: 70px;
  background-color: rgba(255, 255, 255, 0.7);
}

.item-details {
  margin: 60px 0;
}

.item-data {
  margin: 20px 0;
}

.item-data__label {
  margin: 0 0 3px;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
}

.item-data__value {
  font-size: 18px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 25px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 25px;

    &::before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: $color_red;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $color_red;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}
